import * as React from 'react';
import {useState} from 'react';

import {AccountEntryModal} from '../../authentication/AccountEntryModal/AccountEntryModal';
import {AccountFinderModal} from '../../authentication/AccountFinderModal/AccountFinderModal';
import {ImpError} from '../../../client/imp-error/imp-error.class';
import {LinkButton} from '../../components/LinkButton/LinkButton';
import {SessionStorageService} from '../../../client/session-storage/session-storage.service';
import {useOrder} from '../../lib/hooks/use-order';
import {UsersService} from '../../../client/users/users.service';
import {User} from '../../users/user.class';
import {useService} from '../../react/ServiceContext';

export interface HeaderAccountInfoProps {
    user: User;
}

export const HeaderAccountInfo = ({user}: HeaderAccountInfoProps) => {
    const [showAccountEntryModal, setShowAccountEntryModal] = useState(false);
    const [showAccountFinderModal, setShowAccountFinderModal] = useState(false);
    const componentName = `HeaderAccountInfo`;
    const sessionStorageService: SessionStorageService = useService(`sessionStorageService`);
    const usersService: UsersService = useService(`usersService`);
    const {order} = useOrder({componentName});

    // Set activeCatalogId
    const [selectedCatalogId, setSelectedCatalogId] = useState(user.activeCatalogId);

    /**
     * Returns text for Account link in header
     */
    const getAccountLinkText = () => {
        if (user.activeAccount) {
            if (user.getActiveAccountType() === `District`) {
                return `District: ${user.activeAccount}`;
            }
            if (user.getActiveAccountType() === `Division`) {
                return `Division: ${user.activeAccount}`;
            }
            return `Account #: ${user.activeAccount}`;
        }
        return `No Account Selected`;
    };

    /**
     * Selects the user's custom catalog
     * @param catalogId
     */
    const selectCatalog = (catalogId: string) => {
        setSelectedCatalogId(catalogId);
        usersService
            .selectCatalog(catalogId)
            .then(() => {
                location.reload();
            })
            .catch((selectCatalogErr: ImpError) => {
                alert(selectCatalogErr.message);
            });
    };

    /**
     * Template
     */
    return (
        <div
            className="accountInfoLineContainer d-none d-lg-block caption"
            id="HeaderAccountInfo"
        >
            <div className="container-xl">
                <div className="row m-0 py-2">
                    <div className="col-3">
                        {!user.hasCustomCatalogs() && !user.hasMultipleCatalogs() && <a href="/shop-talk">Shop Talk</a>}
                        {user.hasCustomCatalogs() && user.hasMultipleCatalogs() && (
                            <>
                                <label
                                    className="inline"
                                    htmlFor="header_catalogSelectDropdown"
                                >
                                    Catalog
                                </label>{' '}
                                <select
                                    id="header_catalogSelectDropdown"
                                    onChange={(event) => {
                                        selectCatalog(event.target.value);
                                    }}
                                    style={{maxWidth: 200}}
                                    value={selectedCatalogId}
                                >
                                    {user.catalogList.catalogs.map((catalog) => (
                                        <option
                                            key={catalog.cid}
                                            value={catalog.cid}
                                        >
                                            {catalog.name}
                                        </option>
                                    ))}
                                </select>
                            </>
                        )}
                    </div>
                    <div className="col-9 text-right">
                        <div className="vertical-align">
                            {user.isLoggedIn() ? (
                                <>
                                    Hello, <span className="maskPII">{user.fullName}</span>&nbsp;-{' '}
                                    <a
                                        href="/account"
                                        id="header_welcome_yourAccount"
                                        title="Your account functions"
                                    >
                                        {getAccountLinkText()}
                                    </a>
                                    {user.canSwitchAccount() && (
                                        <>
                                            {' '}
                                            -{' '}
                                            <LinkButton
                                                className="caption"
                                                onClick={() => {
                                                    setShowAccountFinderModal(true);
                                                }}
                                                text="Switch Account"
                                            />
                                        </>
                                    )}
                                    {user.isAllAccountAccess() && (
                                        <>
                                            {' '}
                                            -{' '}
                                            <LinkButton
                                                className="caption"
                                                onClick={() => {
                                                    setShowAccountEntryModal(true);
                                                }}
                                                text="Enter Account"
                                            />
                                        </>
                                    )}
                                    {(order || user.hasActiveOrder()) && (
                                        <>
                                            {' '}
                                            -{' '}
                                            <a
                                                href={`/orders/${order?.orderNbr || user.orderNumber || ''}`}
                                                id="header_welcome_currentOrder"
                                            >
                                                Order #{order?.orderNbr || user.orderNumber || ``}
                                            </a>
                                        </>
                                    )}
                                    {user.hasOrderApprovalAccess && (
                                        <>
                                            {' '}
                                            -{' '}
                                            <a
                                                href="/orders/approval"
                                                id="header_welcome_pendingOrders"
                                                title="Processing pending orders"
                                            >
                                                Order Approval
                                            </a>
                                        </>
                                    )}{' '}
                                    -{' '}
                                    <a
                                        href="/logout"
                                        id="header_welcome_logout"
                                        onClick={() => sessionStorageService.clear()}
                                        title="Log out of your account"
                                    >
                                        Log out
                                    </a>
                                    {user.impersonate && <>&nbsp;- OW: {user.impersonate}</>}
                                    {user.isFieldService() && (
                                        <>
                                            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<a href="/fs-photo-uploader">Upload Photos</a>
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    <a
                                        href="/create-account"
                                        id="header_welcome_createAccount"
                                        title="Create Account"
                                    >
                                        Create Account
                                    </a>
                                    &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                                    <LinkButton
                                        className="caption"
                                        id="header_welcome_login"
                                        onClick={() => usersService.showLoginPopUp()}
                                        text="Log In"
                                    />
                                </>
                            )}
                            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                            <a
                                href="/contact-us"
                                id="headerContactUsLink"
                            >
                                Contact Us
                            </a>
                            {user.hasCustomCatalogs() && user.hasMultipleCatalogs() && (
                                <>
                                    &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<a href="/shop-talk">Shop Talk</a>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <AccountEntryModal
                    onHide={() => {
                        setShowAccountEntryModal(false);
                    }}
                    show={showAccountEntryModal}
                />
                <AccountFinderModal
                    onHide={() => setShowAccountFinderModal(false)}
                    selectAccountMode={user.useSelectAccountMode()}
                    show={showAccountFinderModal}
                    user={user}
                />
            </div>
        </div>
    );
};
