const impErrorComponentTemplate = require('./imp-error.component.ejs');
const impWarningComponentTemplate = require('./imp-warning.component.ejs');
const impSuccessComponentTemplate = require('./imp-success.component.ejs');
export type ErrorType = `error` | `success` | `warning`;

export interface ErrorData {
    message?: string;
    name?: string;
    status: number;
    statusText: string;
    responseJSON?: {
        debugData: any;
        message?: string;
        name: string;
        originalResponse: any;
        status: number;
        statusText?: string;
    };
}

export class ImpError extends Error {
    public debugData?: unknown;
    public originalResponse?: any;
    public status: number;

    constructor(errorData: ErrorData) {
        super();

        // Manage error status
        if (errorData.status) {
            this.status = errorData.status;
        } else if (errorData.responseJSON && errorData.responseJSON.status) {
            this.status = errorData.responseJSON.status;
        }

        // Manage error messaging
        if (errorData.responseJSON && errorData.responseJSON.message) {
            this.message = errorData.responseJSON.message;
            if (this.message === `invalid csrf token`) {
                this.message = `Invalid CSRF token, please reload the page and try again`;
            }
        } else if (errorData.responseJSON && errorData.responseJSON.statusText) {
            this.message = errorData.responseJSON.statusText;
        } else if (errorData.statusText) {
            this.message = errorData.statusText;
        } else {
            this.message = `Unknown Error`;
        }

        // Remove leading asterisks
        if (typeof this.message === `string`) {
            this.message = this.message.replace(/^\*\*/, ``);
        }

        // Append originalResponse, if exists
        if (errorData.responseJSON && errorData.responseJSON.originalResponse) {
            this.originalResponse = errorData.responseJSON.originalResponse;
        }

        // Append debugData, if exists
        if (errorData.responseJSON && errorData.responseJSON.debugData) {
            this.debugData = errorData.responseJSON.debugData;
        }
    }

    /**
     * Display error text via provided selector
     * @param errorMsg - Error text you wish to display
     * @param selector - jQuery selector where you want your error to display
     * @param errorTitle - Optional string to use as bolded error title
     * @param errorType - error, warning , or success defaults to error
     */
    static showError(errorMsg: string, selector: string, errorTitle?: string, errorType: ErrorType = `error`): void {
        if (errorMsg) {
            // Save newlines
            errorMsg = errorMsg.replace(/<br>/g, `\n`);

            // Sanitize parameters
            errorMsg = $(`<div>`).html(errorMsg).text();
            errorTitle = $(`<div>`).html(errorTitle).text();

            // replace newlines
            errorMsg = errorMsg.replace(/\n/g, `<br>`);

            // Display error message
            ImpError.showErrorTrustedHTML(errorMsg, selector, errorTitle, errorType);
        }
    }

    /**
     * Displays an error with embedded HTML, must be from a trusted source
     * @param errorMsg - Error text you wish to display
     * @param selector - jQuery selector where you want your error to display
     * @param errorTitle - Optional string to use as bolded error title
     * @param errorType - error, warning , or success defaults to error
     */
    static showErrorTrustedHTML(errorMsg: string, selector: string, errorTitle?: string, errorType: ErrorType = `error`): void {
        // Build errorText
        let errorText = ``;
        if (errorTitle) {
            errorText += `<strong>${errorTitle}</strong><br>`;
        }
        errorText += `${errorMsg}`;

        // Render template
        const errorElementAnchor = $(`${selector}`);
        switch (errorType) {
            case `error`:
                errorElementAnchor.html(
                    impErrorComponentTemplate({
                        errorText,
                    }),
                );
                break;
            case `success`:
                errorElementAnchor.html(
                    impSuccessComponentTemplate({
                        errorText,
                    }),
                );
                break;
            case `warning`:
                errorElementAnchor.html(
                    impWarningComponentTemplate({
                        errorText,
                    }),
                );
                break;
        }
    }

    /**
     * Hide error text via provided selector
     * @param selector - jQuery selector containing your error message
     */
    static hideError(selector: string): void {
        const errorElementAnchor = $(`${selector}`);
        errorElementAnchor.html(``);
    }
}
