import {FetchService} from '../../fetch/fetch.service';
import {ImpError} from '../../imp-error/imp-error.class';
import {RecoParams, RecoResonance} from '../../../shared/reco/reco.class';

export class RecoService {
    constructor(private _fetchService: FetchService) {}

    /**
     * Retrieves reco items using provided recoParams
     * @param recoParams - Params to be relayed to Reco
     */
    public getRecoItems(recoParams: RecoParams): Promise<RecoResonance> {
        return new Promise((resolve, reject) => {
            // Build queryString
            let queryString = `scheme=${recoParams.scheme}`;
            if (recoParams.itemid) {
                queryString = queryString + `&itemid=${recoParams.itemid}`;
            }
            if (recoParams.stepIdPath) {
                queryString = queryString + `&stepIdPath=${recoParams.stepIdPath}`;
            }
            if (recoParams.webcat) {
                queryString = queryString + `&webcat=${recoParams.webcat}`;
            }

            // getRecoItems
            this._fetchService
                .get<any>(`/api/Items/getRecoItems?${queryString}`)
                .then((getRecoItemsRes) => {
                    if (getRecoItemsRes && getRecoItemsRes.resonance) {
                        resolve(getRecoItemsRes.resonance);
                    } else {
                        resolve(null);
                    }
                })
                .catch((getRecoItemsErr: ImpError) => {
                    reject(getRecoItemsErr);
                });
        });
    }

    /**
     * Sends recToken to Monetate for impression tracking
     * @param recoParams
     */
    public sendRecoEvents(recoParams: RecoParams) {
        // Build queryString
        let queryString = ``;
        if (Array.isArray(recoParams.recToken)) {
            queryString += `&recToken=${recoParams.recToken.join('|')}`;
        } else if (recoParams.recToken) {
            queryString += `&recToken=${recoParams.recToken}`;
        }
        if (recoParams.eventType) {
            queryString += `&eventType=${recoParams.eventType}`;
        }

        // Send impression to Monetate
        this._fetchService.get(`/api/items/sendRecoEvents?${queryString}`).catch(() => {
            // Error silently
        });
    }
}
