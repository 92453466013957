import * as React from 'react';
import {useEffect, useState} from 'react';

import {Alerts} from '../../../ui/Alerts/Alerts';
import {ImpError} from '../../../../client/imp-error/imp-error.class';
import {ItemsService} from '../../../../client/items/items.service';
import {sortItems} from '../../../ui/SortFilterFunctions';
import {useService} from '../../../react/ServiceContext';
import {WebcatMeta} from '../../../items/item.class';

interface ProductCategoriesProps {
    source: `hamburger` | `megaMenu`;
}

export const ProductCategories = ({source}: ProductCategoriesProps) => {
    const [errorMessage, setErrorMessage] = useState(``);
    const [productCategories, setProductCategories] = useState<WebcatMeta[]>([]);
    const itemsService: ItemsService = useService(`itemsService`);

    // Get root categories to display
    useEffect(() => {
        itemsService
            .getRootCategories()
            .then((getRootCategoriesRes) => {
                setProductCategories(getRootCategoriesRes);
            })
            .catch((getRootCategoriesErr: ImpError) => {
                setErrorMessage(getRootCategoriesErr.message);
            });
        // We don't want itemsService to trigger changes in this hook
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Template
     */
    return (
        <ul className={`products ${source}`}>
            {errorMessage ? (
                <Alerts
                    variant="danger"
                    message={errorMessage}
                />
            ) : (
                <>
                    {sortItems(productCategories, `name_a`).map((productCategory) => (
                        <React.Fragment key={productCategory.name}>
                            {productCategory && (
                                <li>
                                    <a
                                        href={`/browse/${productCategory.categoryPath}`}
                                        className="tw-flex tw-flex-row justify-content-start tw-items-center"
                                    >
                                        {source === `hamburger` && (
                                            <img
                                                alt={productCategory.description}
                                                height="50"
                                                src={`/ProductImageThumbs67${productCategory.imagePath}`}
                                                width="50"
                                            />
                                        )}
                                        {productCategory.name}
                                    </a>
                                </li>
                            )}
                        </React.Fragment>
                    ))}
                </>
            )}
        </ul>
    );
};
