import styled from '@emotion/styled';

import {colors} from '../../ui/theme';

export const RibbonAdBannerStyles = styled.div`
    background-color: ${colors.gray[150]};
    display: block;
    margin-top: auto;
    position: relative;
    text-align: center;
    text-decoration: none;

    .ribbonAd__content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .ribbonAd__firstLink {
        margin-left: 20px;
    }
`;
