import * as React from 'react';
import * as prismicH from '@prismicio/helpers';

import PromotionTracker from '../../analytics/promotion-tracker/PromotionTracker';
import {CmsService} from '../../../client/cms/cms.service';
import {OurBenefitsCMS} from '../../our-benefits/our-benefits.class';
import {OurBenefitsStyles} from './OurBenefits.styles';
import {useEffect, useState} from 'react';
import {useService} from '../../react/ServiceContext';

type OurBenefitsProps = {
    creativeSource: `Login Modal` | `Login Page` | `Create Account Page`;
    stacked?: boolean;
};

export const OurBenefits = ({creativeSource, stacked}: OurBenefitsProps) => {
    const [ourBenefits, setOurBenefits] = useState<OurBenefitsCMS>(null);
    const cmsService: CmsService = useService(`cmsService`);

    useEffect(() => {
        cmsService
            .getByType<OurBenefitsCMS>(`unlock_our_benefits_component`)
            .then((getByTypeRes) => {
                setOurBenefits(getByTypeRes);
            })
            .catch(() => {
                // Error silently
            });
    }, [cmsService]);

    /**
     * Returns template for "Our Benefits" link
     */
    const getOurBenefitsLink = () => (
        <a
            className="stretched-link text-decoration-none"
            href={ourBenefits.lead_away_link}
        >
            <i className={`fa ${ourBenefits.lead_away_icon} gray-650 mr-2`} />
            {ourBenefits.lead_away_text}
        </a>
    );

    /**
     * Template
     */
    return (
        <OurBenefitsStyles>
            {ourBenefits ? (
                <>
                    <div
                        className="banner-image text-center text-white d-flex justify-content-center align-items-center mb-3"
                        style={{
                            backgroundImage: `url('${ourBenefits.repeatable_background_image.url}')`,
                            backgroundRepeat: `repeat`,
                            height: `75px`,
                            width: `100%`,
                        }}
                    >
                        <h3 className="font-family-industry position-relative">
                            {ourBenefits.floating_copy}
                            <div className="headerUnderline" />
                        </h3>
                    </div>
                    <div className="row">
                        {ourBenefits.value_add_blocks.map((vaBlock, index) => (
                            <div
                                className={`${stacked ? 'col-lg-12' : 'col-md-6'} mb-3`}
                                key={index}
                            >
                                <div className="border h-100 p-2">
                                    <div
                                        className="abs-center child-margin-0 cms-p-0"
                                        dangerouslySetInnerHTML={{__html: prismicH.asHTML(vaBlock.value_add_block)}}
                                    />
                                </div>
                            </div>
                        ))}
                        <div className={`${stacked ? 'col-lg-12' : 'col-md-6'} mb-3`}>
                            <div className="border h-100 p-2">
                                <div className="abs-center child-margin-0">
                                    {ourBenefits.lead_away_promotion_id ? (
                                        <PromotionTracker
                                            as="span"
                                            promotion={{
                                                creative_name: `${ourBenefits.lead_away_promotion_creative}${creativeSource}`,
                                                creative_slot: ourBenefits.value_add_blocks.length + 1,
                                                promotion_id: ourBenefits.lead_away_promotion_id,
                                                promotion_name: ourBenefits.lead_away_promotion_name,
                                            }}
                                        >
                                            {getOurBenefitsLink()}
                                        </PromotionTracker>
                                    ) : (
                                        getOurBenefitsLink()
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className="spinner" />
            )}
        </OurBenefitsStyles>
    );
};
