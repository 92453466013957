interface CartDetailEvent {
    items: MonetateItem[];
    pageType?: `cart`;
}

export interface MonetateItem {
    currency: `USD`;
    productId: string;
    purchaseId?: string;
    quantity: number;
    unitPrice: number;
}

export interface ProductId {
    productId: string;
}

export class MonetateService {
    /**
     * Records monetate addCart event
     * @param pageType
     * @param monetateItems
     */
    public addCart(pageType: string, monetateItems: MonetateItem[]) {
        monetateQ.push([`setPageType`, pageType]);
        monetateQ.push([`addCartRows`, monetateItems]);
        monetateQ.push([`trackData`]);
    }

    /**
     * TBD
     * @param cartDetailEvent
     */
    public cartDetail(cartDetailEvent: CartDetailEvent) {
        monetateQ.push([`setPageType`, cartDetailEvent.pageType]);
        if (cartDetailEvent.items) {
            monetateQ.push([`addCartRows`, cartDetailEvent.items]);
            monetateQ.push([`trackData`]);
        }
    }

    /**
     * Sends provided productIds from search result to monetate
     * @param productIds
     * @param pageType
     */
    public searchResults(productIds: ProductId[], pageType: string) {
        if (productIds.length) {
            monetateQ.push([`addProducts`, productIds]);
            monetateQ.push([`setPageType`, pageType]);
            monetateQ.push([`trackData`]);
        }
    }

    /**
     * Records an item detail view event
     * @param itemNum
     */
    public itemDetail(itemNum: string) {
        monetateQ.push([`setPageType`, `product`]);
        monetateQ.push([
            `addProductDetails`,
            [
                {
                    productId: itemNum,
                },
            ],
        ]);
        monetateQ.push([`trackData`]);
    }

    /**
     * TBD
     * @param monetateItems
     */
    public purchase(monetateItems: MonetateItem[]) {
        monetateQ.push([`setPageType`, `purchase`]);
        monetateQ.push([`addPurchaseRows`, monetateItems]);
        monetateQ.push([`trackData`]);
    }

    /**
     * TBD
     * @param pageType
     */
    public setPageType(pageType: string) {
        monetateQ.push([`setPageType`, pageType]);
        monetateQ.push([`trackData`]);
    }
}
