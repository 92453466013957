import * as React from 'react';
import {faAngleDown, faAngleUp} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {LinkButton} from '../../components/LinkButton/LinkButton';

import {Slice} from '../../cms/cms.types';

export interface NavSegmentProps {
    navSegment?: Slice;
    openSegmentIndex: number;
    segmentIndex: number;
    onClick: (segmentIndex: number) => void;
}

export const NavSegment = ({navSegment, segmentIndex, openSegmentIndex, onClick}: NavSegmentProps) => {
    return (
        <div className="lg:tw-mt-4">
            <div className="toggle-footer-menu tw-px-3 lg:tw-px-0 tw-mb-0">
                <LinkButton
                    className="tw-uppercase !tw-font-extrabold !tw-text-lg tw-w-full"
                    onClick={() => {
                        onClick(segmentIndex);
                    }}
                    text={
                        <div className="tw-flex tw-justify-between">
                            {navSegment.primary.footer_segment_header}
                            <span className="tw-block lg:tw-hidden">
                                <FontAwesomeIcon
                                    suppressHydrationWarning
                                    icon={openSegmentIndex === segmentIndex ? faAngleUp : faAngleDown}
                                    size="lg"
                                />
                            </span>
                        </div>
                    }
                />
            </div>
            <ul className={`tw-mx-3 lg:!tw-mx-0 tw-mb-3${openSegmentIndex === segmentIndex ? ' tw-block' : ' tw-hidden'} lg:!tw-block`}>
                {navSegment.items.map((navLinks, itemIndex) => (
                    <li key={itemIndex}>
                        <a
                            href={navLinks.link_url}
                            id={navLinks.link_id}
                            rel={navLinks.seo_nofollow ? 'nofollow' : ''}
                            target={navLinks.link_target ? '_blank' : ''}
                            data-binding={navLinks.data_binding}
                            dangerouslySetInnerHTML={{__html: navLinks.link_title}}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
};
