import {UrlClass} from '../../shared/tools/UrlClass';
import safeRegex from 'safe-regex2';
import {validOrderRegexString} from '../../shared/orders/order.class';

export class ImpUrl {
    /**
     * Add Url Parameter
     * @param key - the actual parameter
     * @param value - value of the parameter
     * @param url - the url (optional)
     */
    static addParam(key: string, value: string, url?: string): string {
        if (!url) {
            url = window.location.href;
        }
        return UrlClass.addParam(url, key, value);
    }

    /**
     * Returns URL query parameter value
     * @param queryParam {string} query param to retrieve value for
     * @param url {string} URL to parse. Defaults to current URL
     * @returns {string} Requested parameter value
     */
    static getQueryParamVal(queryParam: string, url?: string): string {
        // If url not provided, use current URL
        if (!url) {
            url = window.location.href;
        }

        // Escape brackets from requested param (for regex)
        queryParam = queryParam.replace(/[\[\]]/g, '\\$&');

        if (!safeRegex(`[?&]${queryParam}(=([^&#]*)|&|#|$)`)) {
            return null;
        }
        // Retrieve queryParam value using paramRegex
        const paramRegex = new RegExp(`[?&]${queryParam}(=([^&#]*)|&|#|$)`);
        const regexResults = paramRegex.exec(url);

        // If param not present, return null
        if (!regexResults) {
            return null;
        }

        // If param had no value, return empty string
        if (!regexResults[2]) {
            return ``;
        }

        // Replace win-1252 registered trademark with utf-8 version
        let requestedParamValue = regexResults[2];
        if (requestedParamValue.indexOf(`%AE`) !== -1 && requestedParamValue.indexOf(`%C2%AE`) === -1) {
            requestedParamValue = requestedParamValue.replace(`%AE`, `%C2%AE`);
        }

        // Replace a + with a space
        requestedParamValue = requestedParamValue.replace(/\+/g, ` `);

        // Return decoded value
        try {
            return decodeURIComponent(requestedParamValue);
        } catch (decodeURIComponentErr) {
            return ``;
        }
    }

    /**
     * Retrieves the url segment, indicated by `pos` (an array index)
     * Note that a urlPath starting with `/` is element 0 (returning '')
     * @example
     * // Returns P000W67613
     * ImpUrl.getUrlSegment(2, '/orders/P000W67613/edit');
     * @param pos - Array index of the relative URL to return
     * @param urlPath - Optional relative urlPath to pass in - otherwise defaults to current urlPath
     * @returns {string}
     */
    static getUrlSegment(pos: number, urlPath?: string): string {
        if (!urlPath) {
            urlPath = window.location.pathname;
        }
        const urlPathArray = urlPath.split(`/`);
        return urlPathArray[pos];
    }

    /**
     * Returns true if on job costing related pages
     */
    public static get onJobCostingDetailPage(): boolean {
        return new RegExp(`\/jobcosting\/.+`, `i`).test(window.location.pathname);
    }

    /**
     * Returns true if on checkout or order detail page
     */
    public static get onOrderDetailPage(): boolean {
        const url = window.location.href;
        const orderDetailPageRegex = new RegExp(`\/orders\/${validOrderRegexString}\/edit`, `i`);
        const checkoutPageRegex = new RegExp(`\/orders\/${validOrderRegexString}\/checkout`, `i`);
        return orderDetailPageRegex.test(url) || checkoutPageRegex.test(url);
    }
}
