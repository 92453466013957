import * as React from 'react';

import {CardItem} from '../item.class';
import {ItemCard} from '../ItemCard/ItemCard';
import {OrderItemsWorkflow} from '../../../client/order-items/order-items.workflow';
import {OrderLine} from '../../orders/order.class';
import {OrdersService} from '../../../client/orders/orders.service';
import {User} from '../../users/user.class';
import {useService} from '../../react/ServiceContext';

export interface SwitchAndSaveProps {
    featured?: boolean;
    onReplaceAction?: any;
    orderLineToReplace?: OrderLine;
    percentSaved: number;
    replacementItem: CardItem;
    user: User;
    useReplacementButtons: boolean;
    valueSaved: number;
}

export const SwitchAndSave = ({
    featured,
    onReplaceAction,
    orderLineToReplace,
    percentSaved,
    replacementItem,
    user,
    useReplacementButtons,
    valueSaved,
}: SwitchAndSaveProps) => {
    const componentName = `SwitchAndSave`;
    const orderItemsWorkflow: OrderItemsWorkflow = useService(`orderItemsWorkflow`);
    const ordersService: OrdersService = useService(`ordersService`);

    // Do not render if no replacement item
    if (!replacementItem) {
        return;
    }

    /**
     * Template
     */
    return (
        <div
            className="mt-3"
            data-e2e="SwitchAndSave"
            style={{borderLeft: `5px solid #424242`}}
        >
            <div className="gray-150-bg border-top border-right font-weight-bolder p-2">
                <img
                    alt="Switch and Save Logo"
                    className="mr-3 d-none d-md-inline"
                    src="/dimg/switch_and_save_outlines.svg"
                    style={{width: 102}}
                />{' '}
                Save ${valueSaved} ({percentSaved}%) on this similar item!
            </div>
            <ItemCard
                cardOptions={{
                    featured,
                    hideCheckbox: true,
                    overrideAddButtonText: useReplacementButtons ? `Replace Item` : undefined,
                    overrideAddButtonVariant: `outline-danger`,
                    primaryActionReplace: !!useReplacementButtons,
                }}
                cardItem={replacementItem}
                componentName="SwitchAndSave"
                hidden={false}
                index={0}
                onRemove={undefined}
                onReplace={(oldItemNum, quantity) => {
                    orderItemsWorkflow.replaceOrderLine(
                        orderLineToReplace,
                        {
                            item: replacementItem.item,
                            unitsOrdered: quantity,
                        },
                        componentName,
                        onReplaceAction,
                    );
                }}
                orderNumber={ordersService?.currentOrderNumber}
                recordItemCheckbox={undefined}
                unitsOrdered={1}
                user={user}
            />
        </div>
    );
};
