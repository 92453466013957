import * as React from 'react';
import {faExclamationTriangle} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {ContentModal} from '../../ui/modals/ContentModal';
import {LinkButton} from '../../components/LinkButton/LinkButton';
import {OrderHeader} from '../order-header.class';
import {TemplateHelpers} from '../../tools/template-helpers.class';
import {User} from '../../users/user.class';

interface OpenOrdersModalProps {
    onClose: () => void;
    onSelectOrder: (orderOption: string) => void;
    orderHeaders: OrderHeader[];
    show: boolean;
    user: User;
}

export const OpenOrdersModal = ({onClose, onSelectOrder, orderHeaders, show, user}: OpenOrdersModalProps) => {
    /**
     * Closes modal without selecting an order
     */
    const cancelOrderSelection = () => {
        onSelectOrder(``);
        onClose();
    };

    /**
     * Template
     */
    return (
        <ContentModal
            onClose={cancelOrderSelection}
            footer={
                <button
                    className="btn-modal-action red"
                    data-e2e="manageYourOrderPopup-CreateOrderBtn"
                    onClick={() => {
                        onSelectOrder(`createNewOrder`);
                        onClose();
                    }}
                >
                    Create New Order
                </button>
            }
            show={show}
            title="Manage Your Orders"
        >
            <>
                <h4>Edit an existing order</h4>
                <table className="table table-card">
                    <thead>
                        <tr>
                            <th>Order</th>
                            <th>Order Date</th>
                            <th>PO</th>
                            <th>Location</th>
                            <th>Attention</th>
                            <th>City</th>
                            <th>State</th>
                            {user.canViewPrice() && <th className="tw-text-right">Amount</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {orderHeaders.map((orderHeader) => (
                            <tr key={orderHeader.ordrNbr}>
                                <td data-title="Order:">
                                    <LinkButton
                                        text={orderHeader.ordrNbr}
                                        onClick={() => {
                                            onSelectOrder(orderHeader.ordrNbr);
                                            onClose();
                                        }}
                                    />
                                    {orderHeader.status === `Order Declined` && (
                                        <>
                                            <br />
                                            <span className="error">
                                                <FontAwesomeIcon icon={faExclamationTriangle} />
                                                Declined
                                            </span>
                                        </>
                                    )}
                                </td>
                                <td data-title="Order Date:">{TemplateHelpers.formatDate(orderHeader.ordrDt)}</td>
                                <td
                                    className="maskPII"
                                    data-title="PO:"
                                >
                                    {orderHeader.po}
                                </td>
                                <td
                                    className="maskPII"
                                    data-title="Location:"
                                >
                                    {orderHeader.ordLocation}
                                </td>
                                <td
                                    className="maskPII"
                                    data-title="Attention:"
                                >
                                    {orderHeader.attn}
                                </td>
                                <td data-title="City:">{orderHeader.city}</td>
                                <td data-title="State:">{orderHeader.st}</td>
                                {user.canViewPrice() && (
                                    <td
                                        data-title="Amount:"
                                        className="text-lg-right"
                                    >
                                        {TemplateHelpers.formatCurrency(orderHeader.amount)}
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>
        </ContentModal>
    );
};
