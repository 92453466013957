import {FetchService} from '../fetch/fetch.service';
import {ImpError} from '../imp-error/imp-error.class';
import {
    CreateJobRes,
    CreateRepairOrderParams,
    JobCostingControllerCmd,
    JobCostingRes,
    RoDetails,
    RoJobItem,
} from '../../server/job-costing/job-costing.class';

const dateFormatter = (inDate: Date): string => {
    const month = ('0' + (inDate.getMonth() + 1).toString()).slice(-2);
    const day = ('0' + inDate.getDate().toString()).slice(-2);
    return `${month}/${day}/${inDate.getFullYear().toString().slice(-2)}`;
};

export class JobCostingService {
    private _currentRepairOrder: RoDetails;
    constructor(private _fetchService: FetchService) {}

    /**
     * Creates repair order
     * @param createRepairOrderParams
     */
    public createRepairOrder(createRepairOrderParams: CreateRepairOrderParams) {
        return this._fetchService.post<CreateJobRes>(`/api/job-costing/createRepairOrder`, createRepairOrderParams);
    }

    /**
     * Creates a new roJobItem on supplied roJobId
     * @param roJobId
     * @param roJobItemId
     * @param roJobItemQty
     */
    public createRoJobItem(roJobId: string, roJobItemId: string, roJobItemQty: number) {
        return this._fetchService.post<RoJobItem[]>(`/api/repairOrders/jobs/${roJobId}/createRoJobItem`, {
            qty: roJobItemQty,
            item: roJobItemId,
        });
    }

    /**
     * Returns current repair order
     */
    public get currentRepairOrder() {
        return this._currentRepairOrder;
    }

    /**
     * Sets current repair order
     * @param reDetails
     */
    public set currentRepairOrder(reDetails: RoDetails) {
        this._currentRepairOrder = reDetails;
    }

    /**
     * Deletes repair order
     * @param roJobId
     */
    public deleteRepairOrder(roJobId: string) {
        return this._fetchService.delete<JobCostingRes>(`/api/repairOrders/jobs/${roJobId}`);
    }

    /**
     * Get single job
     * @param jobId
     */
    public getRepairOrder(jobId: string) {
        return new Promise<JobCostingRes>((resolve, reject) => {
            this._fetchService
                .get<JobCostingRes>(`/api/job-costing/getRepairOrder/${jobId}`)
                .then((getRepairOrderRes) => {
                    this.currentRepairOrder = getRepairOrderRes.roDetails;
                    resolve(getRepairOrderRes);
                })
                .catch((getRepairOrderErr: ImpError) => {
                    reject(getRepairOrderErr);
                });
        });
    }

    /**
     * Returns repair orders for account
     */
    public getRepairOrders() {
        return this._fetchService.get<JobCostingRes>(`/api/job-costing/getRepairOrders`);
    }

    /**
     * Opens a repair order on supplied roJobId
     * @param roJobId
     */
    public openRoJob(roJobId: string) {
        return this._fetchService.post<JobCostingRes>(`/api/repairOrders/jobs/${roJobId}/open`);
    }

    /**
     * Creates a new "job costing" repair order
     * @param startDate
     * @param endDate
     * @param includeOpen
     */
    public summaryReport(startDate: Date, endDate: Date, includeOpen: boolean) {
        return this._fetchService.post<JobCostingRes>(`/api/jobCosting/summaryReport`, {
            startDate: dateFormatter(startDate),
            endDate: dateFormatter(endDate),
            includeOpen,
        });
    }

    /**
     * Removes a new roJobItem on supplied roJobId
     * @param roJobId
     * @param roJobItemId
     */
    public removeRoJobItem(roJobId: string, roJobItemId: string) {
        return this._fetchService.post<RoJobItem[]>(`/api/repairOrders/jobs/${roJobId}/removeRoJobItem`, {item: roJobItemId});
    }

    /**
     * Runs a repair order action on supplied roJobId
     * @param roJobId
     * @param action
     */
    public ROAction(roJobId: string, action: JobCostingControllerCmd) {
        return this._fetchService.post<JobCostingRes>(`/api/repairOrders/jobs/${roJobId}/action`, {action});
    }

    /**
     * Submits a roJobItem on supplied roJobId
     * @param roJobId
     */
    public submitRoJob(roJobId: string) {
        return this._fetchService.post<JobCostingRes>(`/api/repairOrders/jobs/${roJobId}/submitRoJob`);
    }

    /**
     * Runs a Repair order action on supplied roJobId
     * @param jobId - Repair order to update
     * @param ronbr - Number to link with RO
     * @param desc - Description for RO
     * @param markup - Markup % for RO
     */
    public updateRepairOrder(jobId: string, ronbr: string, desc: string, markup: number) {
        return this._fetchService.post<JobCostingRes>(`/api/repairOrders/updateRepairOrder`, {
            desc,
            jobId,
            markup,
            ronbr,
        });
    }

    /**
     * Updates a roJobItem on supplied roJobId
     * @param roJobId
     * @param roJobItemId
     * @param roJobItemQty
     */
    public updateRoJobItem(roJobId: string, roJobItemId: string, roJobItemQty: number) {
        return this._fetchService.post<RoJobItem[]>(`/api/repairOrders/jobs/${roJobId}/updateRoJobItem`, {
            qty: roJobItemQty,
            item: roJobItemId,
        });
    }
}
