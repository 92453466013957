import * as React from 'react';
import {faAngleDown} from '@fortawesome/pro-solid-svg-icons';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useEffect, useRef, useState} from 'react';

import {AccountFinderModal} from '../../authentication/AccountFinderModal/AccountFinderModal';
import {ErrorModal} from '../../error-handling/ErrorModal';
import {HeaderDeals} from '../header-deals/HeaderDeals';
import {HeaderQuickAdd} from '../header-quick-add/HeaderQuickAdd';
import {ItemsService} from '../../../client/items/items.service';
import {ListsWorkflow} from '../../../client/lists/lists.workflow';
import {MegaMenuStyles} from './MegaMenu.styles';
import {OrderItemsWorkflow} from '../../../client/order-items/order-items.workflow';
import {Overlay} from '../../../client/ui/components/Overlay';
import {PrismicItem, PrismicContent} from '../../cms/cms.types';
import {ProductCategories} from './ProductCategories/ProductCategories';
import {TopProduct, WebcatMeta} from '../../items/item.class';
import {TopProducts} from '../../top-products/TopProducts';
import {UserStateService} from '../../../client/users/user-state.service';
import {User} from '../../users/user.class';
import {useService} from '../../react/ServiceContext';

export type SelectedMenu = `` | `dealsDropdown` | `productsDropdown` | `quickAddDropdown`;

interface MegaMenuProps {
    megaMenuContent: PrismicContent;
    rootCategories: WebcatMeta[];
    user: User;
}

export const MegaMenu = ({megaMenuContent, rootCategories, user}: MegaMenuProps) => {
    const [displayedMenu, setDisplayedMenu] = useState<SelectedMenu>(``);
    const [errorMessage, setErrorMessage] = useState(``);
    const [getTopProductsErr, setGetTopProductsErr] = useState(false);
    const [loadingTopProducts, setLoadingTopProducts] = useState(false);
    const [showAccountFinderModal, setShowAccountFinderModal] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);
    const [topProductsCol1, setTopProductsCol1] = useState<TopProduct[]>([]);
    const [topProductsCol2, setTopProductsCol2] = useState<TopProduct[]>([]);
    const headerQuickAddComponentName = `HeaderQuickAdd`;
    const itemsService: ItemsService = useService(`itemsService`);
    const listsWorkflow: ListsWorkflow = useService(`listsWorkflow`);
    const navBarRef = useRef(null);
    const orderItemsWorkflow: OrderItemsWorkflow = useService(`orderItemsWorkflow`);
    const userStateService: UserStateService = useService(`userStateService`);

    // Process queued actions
    useEffect(() => {
        if (userStateService.hasPendingAddToOrder(headerQuickAddComponentName)) {
            orderItemsWorkflow.addToOrderModal(userStateService.addToOrderItems, headerQuickAddComponentName, true);
        } else if (userStateService.hasPendingAddToList(headerQuickAddComponentName)) {
            listsWorkflow.addToList(
                userStateService.addToListAction,
                userStateService.addToListItems,
                headerQuickAddComponentName,
                `quick-add megamenu`,
                userStateService.addToListItemDesc,
                userStateService.addToListItemImage,
            );
        }
        // Only run once per page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Close MegaMenu when clicked outside of component
        const handleClickOutside = (event: KeyboardEvent) => {
            if (navBarRef.current && !navBarRef.current.contains(event.target)) {
                close();
            }
        };
        document.addEventListener(`click`, handleClickOutside);

        // Close MegaMenu on escape key
        const handleEscapeKey = (event: KeyboardEvent) => {
            if (event.code === `Escape`) {
                close();
            }
        };
        document.addEventListener(`keydown`, handleEscapeKey);

        // Provide cleanup when MegaMenu is removed
        return () => {
            document.removeEventListener(`click`, handleClickOutside);
            document.removeEventListener(`keydown`, handleEscapeKey);
        };
    }, []);

    /**
     * Closes the mega menu
     */
    const close = () => {
        setDisplayedMenu(``);
        setShowOverlay(false);
    };

    /**
     * Retrieves top products with pricing
     */
    const getTopProducts = () => {
        setLoadingTopProducts(true);
        itemsService
            .getTopProducts()
            .then((getTopProductsRes) => {
                if (getTopProductsRes.dataset?.length > 0) {
                    itemsService
                        .addPricesForItemTypeArray(getTopProductsRes.dataset)
                        .then((addPricesForItemTypeArrayRes) => {
                            splitArray(addPricesForItemTypeArrayRes as TopProduct[]);
                        })
                        .catch(() => {
                            // Assuming error response means they can't see prices, so render without them
                            splitArray(getTopProductsRes.dataset);
                        });
                } else {
                    setLoadingTopProducts(false);
                }
            })
            .catch(() => {
                setLoadingTopProducts(false);
                setGetTopProductsErr(true);
            });
    };

    /**
     * Displays selected menu
     * @param selectedMenu
     */
    const openSelectedMenu = (selectedMenu: SelectedMenu) => {
        // If the same menu was already clicked on, close the MegaMenu
        if (selectedMenu === displayedMenu) {
            close();
        } else {
            setShowOverlay(true);

            // Retrieve top products first time quickAddDropdown is displayed
            if (selectedMenu === `quickAddDropdown` && user.isAccountLevel() && topProductsCol1.length === 0) {
                getTopProducts();
            }

            // Display selected menu
            setDisplayedMenu(selectedMenu);
        }
    };

    /**
     * Controls logic of which menuItems display
     * @param menuItem
     */
    const renderMenuItem = (menuItem: PrismicItem) => {
        if (
            (menuItem.logged_in_only && user.isLoggedIn()) ||
            (menuItem.catalog_restriction && !user.isCatalogRestricted()) ||
            (!menuItem.catalog_restriction && !menuItem.logged_in_only)
        ) {
            return (
                <li key={menuItem.link_text}>
                    <i className={`fa fa-${menuItem.font_awesome_icons} gray-400`} />
                    <a
                        href={menuItem.link}
                        id={menuItem.id}
                        rel={menuItem.seo_nofollow ? `nofollow` : undefined}
                    >
                        {menuItem.link_text}
                    </a>
                </li>
            );
        }
    };

    /**
     * Controls rendering logic for product categories UI
     */
    const renderProductCategories = () => {
        if (user.isLoggedIn()) {
            return (
                <div className="col-8">
                    <ProductCategories source="megaMenu" />
                </div>
            );
        } else if (rootCategories) {
            return (
                <div className="col-8">
                    <ul className="products">
                        {rootCategories.map((rootCategory) => (
                            <li key={rootCategory.id}>
                                <a href={`/browse/${rootCategory.categoryPath}`}>{rootCategory.name}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            );
        }
    };

    /**
     * Splits top products array into two columns
     * @param topProducts
     */
    const splitArray = (topProducts: TopProduct[]) => {
        setTopProductsCol1(topProducts.slice(0, 3));
        setTopProductsCol2(topProducts.slice(3, 6));
        setLoadingTopProducts(false);
    };

    /**
     * Template
     */
    return (
        <>
            <MegaMenuStyles>
                <div
                    className="navBar d-none d-lg-block"
                    ref={navBarRef}
                >
                    <div className="container-xl topnav">
                        <div className="d-flex justify-content-between">
                            <div>
                                <ul className="left-main-nav">
                                    <li
                                        className={displayedMenu === `productsDropdown` ? `active` : ``}
                                        id="menu-products"
                                        onClick={() => openSelectedMenu(`productsDropdown`)}
                                    >
                                        <button
                                            id="topNavProducts"
                                            role="menuitem"
                                        >
                                            PRODUCTS{' '}
                                            <FontAwesomeIcon
                                                icon={faAngleDown}
                                                suppressHydrationWarning={true}
                                            />
                                        </button>
                                    </li>
                                    <li
                                        className={displayedMenu === `quickAddDropdown` ? `active` : ``}
                                        id="header_order"
                                        onClick={() => openSelectedMenu(`quickAddDropdown`)}
                                    >
                                        <button
                                            id="topNavQuickAdd"
                                            role="menuitem"
                                        >
                                            QUICK-ADD{' '}
                                            <FontAwesomeIcon
                                                icon={faAngleDown}
                                                suppressHydrationWarning={true}
                                            />
                                        </button>
                                    </li>
                                    <li>
                                        <a
                                            href="/services"
                                            role="menuitem"
                                        >
                                            SERVICES
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="/featured"
                                            id="topNavFeatured"
                                            role="menuitem"
                                        >
                                            FEATURED
                                        </a>
                                    </li>
                                    {user.hasDeals() && (
                                        <li
                                            className={displayedMenu === `dealsDropdown` ? `active` : ``}
                                            id="menuDeals"
                                            onClick={() => openSelectedMenu(`dealsDropdown`)}
                                        >
                                            <a
                                                id="topNavDeals"
                                                role="menuitem"
                                            >
                                                DEALS{' '}
                                                <FontAwesomeIcon
                                                    icon={faAngleDown}
                                                    suppressHydrationWarning={true}
                                                />
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                            <div>
                                <ul className="right-main-nav">
                                    <li>
                                        <a
                                            href="/order-history-items"
                                            id="topNavOrderHistory"
                                            rel="nofollow"
                                            role="menuitem"
                                        >
                                            Order History
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="/lists"
                                            id="topNavMyLists"
                                            rel="nofollow"
                                            role="menuitem"
                                        >
                                            My Lists
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="/fileimport"
                                            rel="nofollow"
                                            role="menuitem"
                                        >
                                            File Import
                                        </a>
                                    </li>
                                    {!user.isPunchout && (
                                        <li>
                                            <a
                                                href="/manageautoreorders"
                                                id="topNavAutoReorder"
                                                rel="nofollow"
                                                role="menuitem"
                                            >
                                                Auto-Reorder
                                            </a>
                                        </li>
                                    )}
                                    {!user.isCatalogRestricted() && (
                                        <li>
                                            <a
                                                href="/flyer/full-line-catalog"
                                                id="topNavCatalog"
                                                rel="nofollow"
                                                role="menuitem"
                                            >
                                                Catalog
                                            </a>
                                        </li>
                                    )}
                                    {user.hasSampled === true && (
                                        <li>
                                            <a
                                                href="/my-shop/locations"
                                                id="header_my_shop"
                                                role="menuitem"
                                            >
                                                My Shop
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                    {displayedMenu === `productsDropdown` && (
                        <div
                            className="menu-products-dropdown"
                            id="menuProductDropdown"
                        >
                            <div className="container-xl">
                                <div className="tw-flex font-weight-bold mt-3 mb-3">
                                    <div className="tw-grow">
                                        <ul className="shopby-links">
                                            <li>
                                                {' '}
                                                <a
                                                    href="/browse"
                                                    id="shopAllLink"
                                                    tabIndex={0}
                                                >
                                                    <button
                                                        className="btn btn-outline-secondary"
                                                        style={{margin: '-7px 0 0'}}
                                                        tabIndex={-1}
                                                    >
                                                        Shop All
                                                    </button>
                                                </a>
                                            </li>
                                            {megaMenuContent?.body.map((slice) => (
                                                <React.Fragment key={slice.id}>
                                                    {slice.slice_type === `mega_menu_header_links` && (
                                                        <>{slice.items.map((menuItem) => renderMenuItem(menuItem))}</>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="text-right">
                                        <button
                                            className="btn btn-link"
                                            onClick={close}
                                        >
                                            <FontAwesomeIcon
                                                className="gray-400"
                                                icon={faTimes}
                                                size="4x"
                                            />
                                        </button>
                                    </div>
                                </div>
                                <div className="line-separator" />
                                <div className="row mt-4">
                                    {renderProductCategories()}
                                    <div className="col-4">
                                        <div className="emergency-preparedness-nav">
                                            {megaMenuContent?.body.map((slice) => (
                                                <React.Fragment key={slice.id}>
                                                    {slice.slice_type === `gray_block_links` && (
                                                        <div className="mb-3">
                                                            <div className="h5 bold mb-2">{slice.primary.heading}</div>
                                                            <div className="d-flex flex-row flex-wrap">
                                                                {slice.items.map((menuItem, index) => (
                                                                    <div
                                                                        className="mb-1 w-50"
                                                                        key={index}
                                                                    >
                                                                        {menuItem.menu_icon && (
                                                                            <>
                                                                                <i className={`fa fa-${menuItem.menu_icon}`} />
                                                                                &nbsp;
                                                                            </>
                                                                        )}
                                                                        <a href={menuItem.link}>{menuItem.menu_link_text}</a>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {displayedMenu === `quickAddDropdown` && (
                        <div className="quick-add-dropdown">
                            <div
                                className="container-xl mt-4"
                                id="quick-add-container"
                            >
                                <div className="row">
                                    <div className="col-3 border-right">
                                        <HeaderQuickAdd
                                            closeMegaMenu={close}
                                            componentName={headerQuickAddComponentName}
                                            openSelectedMenu={openSelectedMenu}
                                            setErrorMessage={setErrorMessage}
                                            user={user}
                                        />
                                    </div>
                                    <div className="col-9">
                                        <div className="row">
                                            <div className="col-11">
                                                <div className="h4">
                                                    My Most Frequently Purchased Products&nbsp;
                                                    <span className="body pl-3">
                                                        <a
                                                            href="/lists/topunt200?num=5"
                                                            rel="nofollow"
                                                        >
                                                            View All
                                                        </a>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-1 text-right">
                                                <button
                                                    className="btn btn-link"
                                                    onClick={close}
                                                >
                                                    <FontAwesomeIcon
                                                        className="gray-400"
                                                        icon={faTimes}
                                                        size="4x"
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <TopProducts
                                                closeMegaMenu={close}
                                                getTopProductsErr={getTopProductsErr}
                                                loadingTopProducts={loadingTopProducts}
                                                setShowAccountFinderModal={setShowAccountFinderModal}
                                                topProductsCol1={topProductsCol1}
                                                topProductsCol2={topProductsCol2}
                                                user={user}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {displayedMenu === `dealsDropdown` && (
                        <div className="menu-deals-dropdown">
                            <HeaderDeals
                                closeMegaMenu={close}
                                setErrorMessage={setErrorMessage}
                            />
                        </div>
                    )}
                </div>
            </MegaMenuStyles>
            <AccountFinderModal
                onHide={() => setShowAccountFinderModal(false)}
                selectAccountMode={user.useSelectAccountMode()}
                show={showAccountFinderModal}
                user={user}
            />
            <ErrorModal
                errorMsg={errorMessage}
                onClose={() => setErrorMessage(``)}
                show={!!errorMessage}
            />
            <Overlay
                isMenu={true}
                show={showOverlay}
            />
        </>
    );
};
