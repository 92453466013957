import {ListPrivacy, ListType, UpdateListActionTypes} from '../list.class';

type ListLevelTypes = 'Account' | 'District' | 'Division' | 'Parent' | '';
export type ListsSortOrder = 'listName_a' | 'listName_z' | 'numItems_a' | 'numItems_z' | 'owner_a' | 'owner_z' | 'updated_a' | 'updated_z';
export const DEFAULT_LISTS_SORT_ORDER: ListsSortOrder = `updated_z`;
export const emptyListOperation: ListOperation = {
    listAction: null,
    listId: ``,
    listName: ``,
    listItem: ``,
    listType: ``,
    roQty: null,
    comment: ``,
};

export interface ListOperation {
    invnControl?: string;
    listAction: UpdateListActionTypes;
    listId: string;
    listName: string;
    listType?: ListType;
    listItem?: string;
    roQty?: number;
    comment?: string;
}

export interface UserList {
    canCopy: boolean;
    canEdit: boolean;
    canEmail: boolean;
    createdBy: string;
    defaultImg: string;
    defaultImgPath: string;
    defaultList: boolean;
    desc: string;
    ezcmd: string;
    ezid: number;
    formattedItemCount: string;
    hasDiscontinued?: boolean;
    hidden?: boolean;
    imagePath?: string[];
    images: string[];
    internal: boolean;
    itemCnt: number;
    lastAccessDate: string;
    lastModDate: string;
    lastModDateTime: string;
    lastModTime: string;
    lastModUser: string;
    level: ListLevelTypes;
    listId: string;
    listLinkId: string;
    listPrivacy: ListPrivacy;
    listType: ListType;
}

export interface WidgetCMSLists {
    custom_widget_title: string;
    account_type: string;
    layout_type: 'Grid View' | 'List View';
    lists: {
        custom_pill?: string;
        custom_sharing_ids?: string;
        document_sharing?: string;
        full_url?: string;
        image_url: string;
        item_id: string;
        list_name?: string;
        list_number: string;
    }[];
}

export class Lists {
    result: 'Error' | 'OK';
    userLists: UserList[] = [];
    widgetCMSLists: WidgetCMSLists[] = [];

    /**
     * Returns only editable userLists
     * @param userLists
     */
    public static getEditableLists(userLists: UserList[]): UserList[] {
        const editableUserList = [];
        if (userLists && userLists.length) {
            for (const userList of userLists) {
                if (userList.canEdit) {
                    editableUserList.push(userList);
                }
            }
        }
        return editableUserList;
    }

    /**
     * Sorts provided userLists, by specified sortType
     * @param userLists - UserList[] to sort
     * @param sortOrder - Sort type
     */
    public static sortUserLists(userLists: UserList[], sortOrder: ListsSortOrder): UserList[] {
        let fieldName;
        let sortDec = false;
        const sortedUserLists = userLists;

        // Set sorting fields
        switch (sortOrder) {
            case `updated_z`:
                fieldName = `lastModDateTime`;
                sortDec = true;
                break;
            case `updated_a`:
                fieldName = `lastModDateTime`;
                break;
            case `listName_a`:
                fieldName = `desc`;
                break;
            case `listName_z`:
                fieldName = `desc`;
                sortDec = true;
                break;
            case `owner_a`:
                fieldName = `createdBy`;
                break;
            case `owner_z`:
                fieldName = `createdBy`;
                sortDec = true;
                break;
            case `numItems_a`:
                fieldName = `itemCnt`;
                break;
            case `numItems_z`:
                fieldName = `itemCnt`;
                sortDec = true;
                break;
        }

        // Sort userLists
        return sortedUserLists.sort((userList1, userList2) => {
            let valueA = userList1[fieldName];
            let valueB = userList2[fieldName];
            if (fieldName === `itemCnt` && userList1.ezcmd !== '') {
                valueA = Infinity;
            }
            if (fieldName === `itemCnt` && userList2.ezcmd !== '') {
                valueB = Infinity;
            }
            if (valueA && valueA.toLowerCase) {
                valueA = valueA.toLowerCase();
            }
            if (valueB && valueB.toLowerCase) {
                valueB = valueB.toLowerCase();
            }
            if (valueA < valueB) {
                return sortDec ? 1 : -1;
            }
            if (valueA > valueB) {
                return sortDec ? -1 : 1;
            }
            return 0;
        });
    }
}
